<template>
    <vs-popup :active.sync="activePrompt" @close="onClose" title="Activate your business" class="activation">
        <vs-row class="dialog-body">
            <vs-col
            type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="12"
            class="left"
            >
            <h6 class="font-weight-normal m-3">Complete and submit this form to activate your business.</h6>
            <div @click="activateButton('contact')" :class="contactActive?'content-active w-100 mt-4 p-3':'inactive w-100 mt-4 p-3'"><span v-if="contactComplete"><vs-icon color="success" icon="check"></vs-icon></span> Contact Info <span v-if="contactActive" class="float-right">></span></div>
            <div @click="activateButton('overview')" :class="businessOverviewActive?'content-active w-100 p-3':'inactive w-100 p-3'"><span v-if="businessOverviewComplete"><vs-icon color="success" icon="check"></vs-icon></span> Business Overview <span v-if="businessOverviewActive" class="float-right">></span></div>
            <div @click="activateButton('details')" :class="businessDetailsActive?'content-active w-100 p-3':'inactive w-100 p-3'"><span v-if="businessDetailsComplete"><vs-icon color="success" icon="check"></vs-icon></span> Business Details <span v-if="businessDetailsActive" class="float-right">></span></div>
            <div @click="activateButton('bank')" :class="bankDetailsActive?'content-active w-100 p-3':'inactive w-100 p-3'"><span v-if="bankDetailsComplete"><vs-icon color="success" icon="check"></vs-icon></span> Bank Details <span v-if="bankDetailsActive" class="float-right">></span></div>
            </vs-col>
            <vs-col
            type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="9"
            vs-sm="9"
            vs-xs="12"
            class="bg-light right p-0"
            >
            <div class="content p-4">
                <div v-if="contactActive">
                    <h3 class="text-muted">CONTACT INFORMATION</h3>
                    <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end align-items-center h-100"><h6>Contact Name*</h6></div>
                        </vs-col>
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <vs-input placeholder="Your name" :danger="!name" danger-text="Please provide your name" v-model="name"></vs-input></vs-col>
                    </vs-row>
                    <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end align-items-center h-100"><h6>Contact Number*</h6></div>
                        </vs-col>
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <vs-input placeholder="Phone number" :danger="!number" danger-text="Please provide your phone number" v-model="number"></vs-input>
                        </vs-col>
                    </vs-row>
                    <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end align-items-center h-100"><h6>Contact Email*</h6></div>
                        </vs-col>
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <vs-input placeholder="Account email" :danger="!email" danger-text="Please provide your email" v-model="email"></vs-input>
                        </vs-col>
                    </vs-row>
                     <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end align-items-center h-100"><h6>Support Email*</h6></div>
                        </vs-col>
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <div class="d-flex flex-wrap">
                                <vs-input placeholder="Your support email" :danger="!supporEmail" danger-text="Please provide a support email" v-model="supporEmail"></vs-input>
                                <vs-checkbox v-model="useContactEmailAsSupport" @change="updateSupport">Use contact email</vs-checkbox>
                            </div>
                        </vs-col>
                    </vs-row>
                     <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end align-items-center h-100"><h6>Dispute Email*</h6></div>
                        </vs-col>
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <div class="d-flex flex-wrap">
                                <vs-input placeholder="Email for handling disputes" :danger="!disputeEmail" danger-text="Please provide a dispute email" v-model="disputeEmail"></vs-input>
                                <vs-checkbox v-model="useContactEmailAsDispute" @change="updateDispute">Use contact email</vs-checkbox>
                            </div>
                        </vs-col>
                    </vs-row>
                </div>
                <div v-if="businessOverviewActive">
                    <h3 class="text-muted">BUSINESS OVERVIEW</h3>
                    <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end align-items-center h-100"><h6>Business Type*</h6></div>
                        </vs-col>
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <vs-select v-model="selectedBusinessType">
                                <vs-select-item v-for="(item,index) in businessTypes" :key="index" :value="item" :text="item"></vs-select-item>
                            </vs-select>
                        </vs-col>
                    </vs-row>
                    <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end align-items-center h-100"><h6>Business Category*</h6></div>
                        </vs-col>
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <vs-select v-model="selectedBusinessCategory">
                                <vs-select-item v-for="(item,index) in businessCategories" :key="index" :value="item" :text="item"></vs-select-item>
                            </vs-select>
                        </vs-col>
                    </vs-row>
                    <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end mt-1"><h6>Website URL</h6></div>
                        </vs-col>
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <div class="d-flex flex-wrap">
                                <vs-input placeholder="Website url" :disabled="noWebsite" v-model="website"></vs-input>
                                <vs-checkbox v-model="noWebsite">We do not have a website</vs-checkbox>
                            </div>
                            <div class="mt-3 text-muted">
                                <p>Your website should contain the following:</p>
                                <div class="d-flex">
                                    <ul>
                                        <li>About us</li>
                                        <li>Contact us</li>
                                    </ul>
                                    <ul class="ml-4">
                                        <li><a href="https://utupay.africa/merchant-policy">Privacy Policy</a></li>
                                        <li><a href="https://utupay.africa/merchant-terms">Terms &amp; Conditions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </vs-col>
                    </vs-row>
                </div>
                <div v-if="businessDetailsActive" class="pb-3">
                    <h3 class="text-muted">BUSINESS DETAILS</h3>
                    <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end align-items-center h-100"><h6>Business Name*</h6></div>
                        </vs-col>
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <vs-input class="w-100" placeholder="Buniness name" v-model="businessName"></vs-input>
                        </vs-col>
                    </vs-row>
                    <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end align-items-center h-100"><h6>Business Description*</h6></div>
                        </vs-col>
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <vs-textarea class="change-background" height="100px" width="100%" placeholder="Describe what your business does" v-model="businessDescription"></vs-textarea>
                        </vs-col>
                    </vs-row>
                    <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end align-items-center h-100"><h6>Staff Size*</h6></div>
                        </vs-col>
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <vs-select v-model="selectedStaffSize" class="w-100">
                                <vs-select-item v-for="(item,index) in staffSizeOptions" :key="index" :value="item" :text="item"></vs-select-item>
                            </vs-select>
                        </vs-col>
                    </vs-row>
                    <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end mt-2"><h6>Business Address*</h6></div>
                        </vs-col>
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <vs-input label="Enter your office address*" v-model="businessAddress" class="mt-2 w-100"></vs-input>
                        </vs-col>
                    </vs-row>
                    <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end align-items-center h-100"><h6>Certificate of Incorporation/Registration*</h6></div>
                        </vs-col>
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <div class="d-flex flex-wrap align-items-center ">
                                <!-- <div class="bg-primary d-flex align-items-center p-3 mr-2"><span class="text-white"><vs-icon icon="cloud_upload" color="white" class="mr-3"></vs-icon>Upload your file</span></div> -->
                                <vs-button @click="upload('registration')" icon="cloud_upload">Upload your document</vs-button>
                                <p class="ml-2">{{registrationFile}}</p>
                            </div>
                        </vs-col>
                    </vs-row>
                    <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end align-items-center h-100"><h6>Memorandum and Articles of Association*</h6></div>
                        </vs-col>
                        <vs-col class="" type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <div class="d-flex flex-wrap align-items-center h-100">
                                <vs-button @click="upload('memorandum')" icon="cloud_upload">Upload your document</vs-button>
                                <p class="ml-2">{{memorandumFile}}</p>
                            </div>
                        </vs-col>
                    </vs-row>
                    <br class="mt-4">
                    <vs-divider>Company Authorization Details</vs-divider>
                    <br>
                    <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end"><h6>Authorized Representative and Signee*</h6></div>
                            <p class="mt-3">You must be legally capable or have been authorized to enter into this agreement. </p>
                        </vs-col>
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <vs-row class="w-100">
                                <vs-col class="pl-0" type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6">
                                    <div class="d-flex justify-content-start"><h6 class="font-weight-normal">Are you a co-founder?</h6></div>
                                </vs-col>
                                <vs-col class="pr-0" type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6">
                                    <div class="d-flex justify-content-left m-0">
                                        <vs-radio v-model="coFounder" vs-value="yes">Yes</vs-radio>
                                        <vs-radio class="ml-3" v-model="coFounder" vs-value="no">No</vs-radio>
                                    </div>
                                </vs-col>
                            </vs-row>
                            <vs-row class="w-100 mt-4">
                                <vs-col class="pl-0" type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6">
                                    <vs-input label="What is your Full Name" v-model="authorizedName" class="mt-2 w-100"></vs-input>
                                </vs-col>
                                <vs-col class="pr-0" type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6">
                                    <vs-input label="What is your Phone Number" v-model="authorizedPhoneNumber" class="mt-2 w-100"></vs-input>
                                </vs-col>
                            </vs-row>
                            <vs-row class="w-100 mt-4">
                                <vs-col class="pl-0" type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6">
                                    <vs-input label="What is your Email Address" v-model="authorizedEmail" class="mt-2 w-100"></vs-input>
                                </vs-col>
                                <vs-col class="pr-0" type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6">
                                    <vs-input label="What is your BVN" v-model="authorizedBVN" class="mt-2 w-100"></vs-input>
                                </vs-col>
                            </vs-row>
                            <div v-if="coFounder === 'no'" class="mt-4">
                                <vs-input label="What is your Job Title" v-model="authorizedJobTitle" class="mt-2 w-100"></vs-input>
                            </div>
                            <vs-row class="w-100 mt-4">
                                <vs-col class="pl-0" type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6">
                                    <vs-input label="What is your Street Address" v-model="authorizedStreetAddress" class="mt-2 w-100"></vs-input>
                                </vs-col>
                                <vs-col class="pr-0" type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6">
                                    <vs-select v-model="selectedCountry" label="Select Country" class="mt-2" @change="onCountrySelected">
                                        <vs-select-item v-for="(item,index) in countryNames" :key="index" :value="item" :text="item"></vs-select-item>
                                    </vs-select>
                                </vs-col>
                            </vs-row>
                            <vs-row class="w-100 mt-4">
                                <vs-col class="pl-0" type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6">
                                    <vs-select v-model="selectedState" label="Select State" class="mt-2" @change="onStateSelected">
                                        <vs-select-item v-for="(item,index) in stateNames" :key="index" :value="item" :text="item"></vs-select-item>
                                    </vs-select>
                                </vs-col>
                                <vs-col class="pr-0" type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6">
                                    <vs-select v-model="selectedCity" label="Select City" class="mt-2">
                                        <vs-select-item v-for="(item,index) in cityNames" :key="index" :value="item" :text="item"></vs-select-item>
                                    </vs-select>
                                </vs-col>
                            </vs-row>
                        </vs-col>
                    </vs-row>
                </div>
                <div v-if="bankDetailsActive">
                    <h3 class="text-muted">BANK DETAILS</h3>
                    <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end mt-1"><h6>Phone number*</h6></div>
                        </vs-col>
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <div class="d-flex flex-wrap">
                                <vs-input placeholder="Enter phone number" v-model="accountPhoneNumber"></vs-input>
                                <vs-checkbox v-model="useContactNumberAsAccount" @change="updateNumber">Use contact number</vs-checkbox>
                            </div>
                        </vs-col>
                    </vs-row>
                    <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end align-items-center h-100"><h6>Select Bank Name*</h6></div>
                        </vs-col>
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <vs-select v-model="selectedBank">
                                <vs-select-item v-for="(item,index) in banksOption" :key="index" :value="item" :text="item"></vs-select-item>
                            </vs-select>
                        </vs-col>
                    </vs-row>
                    <vs-row class="mt-4">
                        <vs-col type="flex" vs-justify="right" vs-align="right" vs-lg="4" vs-sm="4" vs-xs="4">
                            <div class="d-flex justify-content-end align-items-center h-100"><h6>Corporate Account Number*</h6></div>
                        </vs-col>
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8">
                            <vs-input placeholder="Enter account number" v-model="accountNumber"></vs-input>
                        </vs-col>
                    </vs-row>
                </div>
            </div>
            <div class="content-footer h-15 d-flex flex-row-reverse align-items-center">
                <div class="mr-4">
                    <vs-button type="border" color="gray" @click="onClose">Cancel</vs-button>
                    <vs-button :disabled="buttonDisabled" @click="handleSaveClick" class="ml-4">{{saveButton}} ></vs-button>
                </div>
            </div>
            </vs-col>
        </vs-row>
        <image-upload field="img"
            @crop-success="cropSuccess"
            @crop-upload-success="cropUploadSuccess"
            @crop-upload-fail="cropUploadFail"
            @src-file-set="fileSet"
            v-model="show"
            :width="600"
            :height="800"
            :params="params"
            :headers="headers"
            :no-rotate="false"
            langType="en"
            img-format="jpg">
        </image-upload>
    </vs-popup>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import banks from '../../../utils/banks';
import businessCategories from '../../../utils/businessCategories';
import countryList from '../../../utils/countries';
import ImageUpload from "vue-image-crop-upload"

export default {
    name: "ActivationDialog",
    components: { ImageUpload },
    props: {
        promptProp: {
            type: Boolean,
            default: false
        }
    },
    created() {
        this.name = this.user? this.user.user_name : '';
        this.number = this.user? this.user.user_phone_number : '';
        this.email = this.user? this.user.user_email : '';
    },
    data() {
        return {
            contactActive: true,
            businessOverviewActive: false,
            businessDetailsActive: false,
            bankDetailsActive: false,
            contactComplete: false,
            businessOverviewComplete: false,
            businessDetailsComplete: false,
            bankDetailsComplete: false,
            saveButton: 'Save and continue',
            buttonDisabled: false,
            noWebsite: false,
            selectedBusinessType: '--Select--',
            selectedBusinessCategory: '--Select--',
            businessTypes: ['--Select--', 'Private Limited', 'Public Limited', 'Partnership', 'LLC', 'NGO', 'Proprietorship', 'Not yet registered'],
            businessCategories: businessCategories.sort(),
            website: '',
            selectedStaffSize: '--Select--',
            staffSizeOptions: ['--Select--', '1 - 5 people', '5 - 20 people', '20 - 50 people', 'Over 50 people'],
            supporEmail: '',
            disputeEmail: '',
            useContactEmailAsSupport: false,
            useContactEmailAsDispute: false,
            number: '',
            name: '',
            email: '',
            coFounder: 'yes',
            useContactNumberAsAccount: false,
            banksOption: banks,
            selectedBank: '',
            show: false,
            params: {
                token: '123456798',
                name: 'avatar'
            },
            headers: {
                smail: '*_~'
            },
            registrationImgDataUrl: '',
            memorandumImgDataUrl: '',
            currentupload: '',
            selectedCountry: '',
            countryNames: countryList.countryNames(),
            selectedState: '',
            stateNames: [],
            selectedCity: '',
            cityNames: [],
            registrationFile: '',
            memorandumFile: '',
            businessName: '',
            businessDescription: '',
            businessAddress: '',
            authorizedName: '',
            authorizedPhoneNumber: '',
            authorizedEmail: '',
            authorizedBVN: '',
            authorizedJobTitle: '',
            authorizedStreetAddress: '',
            accountNumber: '',
            accountPhoneNumber: '',
            fileDetails: ''
        }
    },
    computed: {
        ...mapState(['user']),
        activePrompt: {
            get: function() {
                return this.promptProp;
            },
            set: function() {
                //this.promptProp = value;
            }
        },
        isValid() {
            return this.password.length > 0;
        }
    },
    methods: {
        ...mapActions(['activate']),
        onClose() {
            this.$emit('update:promptProp', false);
            this.$emit('on-close', true);
            //this.activePrompt = false;
        },
        activateButton(val) {
            switch(val) {
                case 'contact':
                    this.contactActive = true;
                    this.businessOverviewActive = false;
                    this.businessDetailsActive = false;
                    this.bankDetailsActive = false;
                    this.saveButton = 'Save and continue';
                    this.buttonDisabled = false;
                    break;
                case 'overview':
                    this.contactActive = false;
                    this.businessOverviewActive = true;
                    this.businessDetailsActive = false;
                    this.bankDetailsActive = false;
                    this.saveButton = 'Save and continue';
                    this.buttonDisabled = false;
                    break;
                case 'details':
                    this.contactActive = false;
                    this.businessOverviewActive = false;
                    this.businessDetailsActive = true;
                    this.bankDetailsActive = false;
                    this.saveButton = 'Save and continue';
                    this.buttonDisabled = false;
                    break;
                case 'bank':
                    this.contactActive = false;
                    this.businessOverviewActive = false;
                    this.businessDetailsActive = false;
                    this.bankDetailsActive = true;
                    this.saveButton = 'Submit and Activate';
                    if (this.allValidated()) {
                        this.buttonDisabled = false;
                    } else this.buttonDisabled = true;
                    break;
            }
        },
        handleSaveClick() {
            if (this.contactActive) {

                if (this.validateContactInfo()) this.contactComplete = true;
                else this.contactComplete = false;

                this.activateButton('overview');
            } else if (this.businessOverviewActive) {
                if (this.validateBusinessOverview()) this.businessOverviewComplete = true;
                else this.businessOverviewComplete = false;

                this.activateButton('details');
            } else if (this.businessDetailsActive) {
                if (this.validateBusinessDetails()) this.businessDetailsComplete = true;
                else this.businessDetailsComplete = false;

                this.activateButton('bank');
            } else if (this.bankDetailsActive) {
                // Perform overall validation before uploads.
                if (this.allValidated()) {
                    this.processActivation();
                }
            }
        },
        allValidated() {
            return this.validateContactInfo() && this.validateBusinessOverview() && this.validateBusinessDetails() && this.validateBankDetails();
        },
        updateSupport() {
            this.supporEmail = this.useContactEmailAsSupport ? this.email : '';
        },
        updateDispute() {
            this.disputeEmail = this.useContactEmailAsDispute ? this.email : '';
        },
        updateNumber() {
            this.accountPhoneNumber = this.useContactNumberAsAccount ? this.number : '';
        },
        upload(type) {
            this.currentupload = type;
            this.show = true;
        },
        fileSet(fileName, fileType, fileSize) {
            this.fileDetails = fileName + ' ( ' + fileType + ", " + this.formatBytes(fileSize) + ' )';
        },
        formatBytes(a,b=2){if(0===a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return parseFloat((a/Math.pow(1024,d)).toFixed(c))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]},
        cropSuccess(imgUrl, field) {
            if (this.currentupload === 'registration') {
                this.registrationImgDataUrl = imgUrl;
                this.registrationFile = this.fileDetails;
            }
            if (this.currentupload === 'memorandum') {
                this.memorandumImgDataUrl = imgUrl;
                this.memorandumFile = this.fileDetails;
            }
            window.console.log('Crop successful:', field);
        },
        cropUploadSuccess() {},
        cropUploadFail() {},
        processActivation() {
            this.$vs.loading();
            let payload = {
                data: {
                    user_name: this.name,
                    user_phone_number: this.number,
                    user_email: this.email,
                    support_email: this.supporEmail,
                    dispute_email: this.disputeEmail,
                    business_type: this.selectedBusinessType,
                    business_category: this.selectedBusinessCategory,
                    website_url: this.website,
                    website_available: !this.noWebsite,
                    business_name: this.businessName,
                    business_description: this.businessDescription,
                    staff_size: this.selectedStaffSize,
                    business_address: this.businessAddress,
                    is_cofounder: this.coFounder === 'yes',
                    authorized_job_title: this.authorizedJobTitle,
                    authorized_name: this.authorizedName,
                    authorized_phone_number: this.authorizedPhoneNumber,
                    authorized_email: this.authorizedEmail,
                    authonrized_bvn: this.authorizedBVN,
                    authorized_street_address: this.authorizedStreetAddress,
                    authorized_counutry: this.selectedCountry,
                    authorized_state: this.selectedState,
                    authorized_city: this.selectedCity,
                    account_phone_number: this.accountPhoneNumber,
                    bank_name: this.selectedBank,
                    account_number: this.accountNumber
                },
                files: {
                    registration_document: this.registrationImgDataUrl,
                    memorandum_document: this.memorandumImgDataUrl
                },
                closeAnimation: this.$vs.loading.close,
                close: () => this.$emit('update:promptProp', false)
            };
            this.activate(payload);
        },
        onCountrySelected() {
            this.stateNames = countryList.countryStates(this.selectedCountry);
            this.cityNames = [];
            this.selectedState = '';
        },
        onStateSelected() {
            this.cityNames = countryList.countryCities(this.selectedCountry, this.selectedState);
            this.selectedCity = '';
        },
        validateContactInfo() {
            return (this.name !== '' && this.number !== '' && this.email !== '' && this.supporEmail !== '' && this.disputeEmail !== '');
        },
        validateBusinessOverview() {
            return (this.selectedBusinessType !== '' && this.selectedBusinessCategory !== '' && this.noWebsite ? true : this.website !== '');
        },
        validateBusinessDetails() {
            return (this.businessName !== '' && this.businessDescription !== '' && this.selectedStaffSize !== '' && this.businessAddress !== '' && this.registrationImgDataUrl !== '' && this.memorandumImgDataUrl !== '' && this.authorizedName !== '' && this.authorizedPhoneNumber !== '' && this.authorizedEmail !== '' && this.authorizedBVN !== '' && (this.coFounder === 'no' ? this.authorizedJobTitle !== '' : true) && this.authorizedStreetAddress !== '' && this.selectedCountry !== '' && this.selectedState !== '' && this.selectedCity !== '');
        },
        validateBankDetails() {
            return (this.accountPhoneNumber !== '' && this.selectedBank !== '' && this.accountNumber.length === 10);
        }
    },
    watch: {
        accountPhoneNumber: function() {
            if (this.bankDetailsActive && this.allValidated()) {
                this.buttonDisabled = false
            } else this.buttonDisabled = true;
        },
        selectedBank: function() {
            if (this.bankDetailsActive && this.allValidated()) {
                this.buttonDisabled = false
            } else this.buttonDisabled = true;
        },
        accountNumber: function() {
            if (this.bankDetailsActive && this.allValidated()) {
                this.buttonDisabled = false
            } else this.buttonDisabled = true;
        }
    }
}
</script>

<style scoped>
    .centerx {
        margin: auto;
    }

    .dialog-body {
        height: 100%;
    }

    .remove-curve {
        margin-left: -10px;
        margin-right: 0px;
    }

    .left {
        padding: 0;
        height: 100%;
    }
    
    .right {
        height: 100%;
        border-radius: 0px, 0px 500px 0px;
        background-color: grey;
    }

    .content-active {
        background-color: #492540;
        color: white;
        font-size: 16px;
        cursor: pointer;
    }

    .inactive {
        background-color: transparent;
        color: black;
        font-size: 16px;
        cursor: pointer;
    }

    .content {
        height: 85%;
        overflow-y: scroll;
    }

    .content-footer {
        height: 15%;
        background-color: #F2F2F2;
    }

    .change-background {
        background-color: #fff;
    }

</style>