export default [
    '--Select--',
    'Ecommerce',
    'Education',
    'Charity',
    'Catering',
    'Fashion and Lifestyle',
    'Food and Beverage',
    'Grocery',
    'IT and Software',
    'Healthcare',
    'Utilities',
    'Services',
    'Web Design/Development',
    'Mobile App Development',
    'Software Development',
    'Accounting Services',
    'Ad/Coupon/Gift Cards/Deals Services',
    'Automotive Repair Shops',
    'Cab Services',
    'Data Processing',
    'Consultation/PR Services',
    'Financial Services',
    'Gaming',
    'General Merchandise Stores',
    'Government Agencies',
    'Health and Beauty Spas',
    'Housing and Real Estate',
    'Logistics',
    'Media and Entertainment',
    'Not-For-Profit',
    'Platform as a Service (Paas)',
    'Software as a Service (SaaS)',
    'Real Estate Agents/Rentals',
    'Service Center',
    'Social',
    'Telecom Services',
    'Travel and Tourism',
    'Transportation',
    'Utilities - Electric, Gas, Water, Oil etc.',
    'Others'
];