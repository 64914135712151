<template>
    <div>
        <ActivationDialog :promptProp.sync="prompt" @on-close="onClose"/>
    </div>
</template>

<script>
import ActivationDialog from './components/custom/ActivationDialog';

export default {
    name: 'Activation',
    components: {ActivationDialog},
    activated() {
        this.prompt = true;
    },
    deactivated() {
        this.prompt = false;
    },
    data() {
        return {
            prompt: true
        }
    },
    methods: {
        onClose() {
            this.$router.go(-1);
        }
    },
}
</script>