import countries from './countriesRaw.json';

function countryNames() {
    let names = [];
    for (let c of countries) {
        names.push(c.name);
    }
    return names;
}

function countryStates(country) {
    let names = [];
    let s = countries[countries.findIndex(item => item.name == country)];
    if (s) {
        let states = s.states;
        for (let s of states) {
            names.push(s.name);
        }
    }
    window.console.log('states', names);
    return names;
}

function countryCities(country, state) {
    let names = [];
    let s = countries[countries.findIndex(item => item.name == country)];
    if (s) {
        let states = s.states;
        let c = states[states.findIndex(item => item.name == state)];
        if (c) {
            let cities = c.cities;
            for (let c of cities) {
                names.push(c);
            }
        }
    }
    return names;
}

export default {countryNames, countryStates, countryCities};